import React, { useRef, useEffect, useState } from 'react';
import {
  CardContent,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from 'react-redux';
import { BlocItemProps } from './models/Index';
import NotationList from './NotationList';
import VideoPlayer from './VideoPlayer';
import Buttons from './Buttons';
import styles from './BlocItemStyles.module.css';
import Player from '@vimeo/player';
import { setCurrentProsa, setCurrentNotationIndex } from '../Store';
import SpeedControl from './SpeedControl';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase-config';
import Prosa from './Prosa';
import InfoIcon from '@mui/icons-material/Info';
import FacebookShareButton from './FacebookShareButton'; // Import the new component

const BlocItem: React.FC<BlocItemProps> = ({ blocId, index }) => {
  const notationRefs = useRef<Array<HTMLDivElement | null>>([]);
  const dispatch = useDispatch();

  const [bloc, setBloc] = useState<any>(null);
  const [startBeat, setStartBeat] = useState<string | null>(null);
  const [endBeat, setEndBeat] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [showCountdown, setShowCountdown] = useState(false);
  const [countdownBeats, setCountdownBeats] = useState<number[]>([]); // New state for beats

  const currentProsa = useSelector((state: any) => state.bloc.currentProsa);
  const currentNotationIndex = useSelector((state: any) => state.bloc.currentNotationIndex);
  const playbackSpeed = useSelector((state: any) => state.bloc.playbackSpeed);

  const rawFeedback = bloc?.notationArray[currentNotationIndex]?.rawFeedback || '';
  const [isProsaOpen, setIsProsaOpen] = useState(false);

  const toggleProsa = () => {
    setIsProsaOpen((prev) => !prev);
  };

  useEffect(() => {
    const blocDocRef = doc(db, 'blocs', blocId);

    const unsubscribe = onSnapshot(blocDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setBloc(docSnapshot.data());
      } else {
        console.error('Bloc document does not exist.');
      }
    });

    return () => unsubscribe();
  }, [blocId]);

  const handleStartBeatChange = (event: SelectChangeEvent<string>) => {
    setStartBeat(event.target.value);
    setEndBeat(null);
  };

  const handleEndBeatChange = (event: SelectChangeEvent<string>) => {
    setEndBeat(event.target.value);
  };

  useEffect(() => {
    if (bloc?.videoUrl) {
      const iframe = document.getElementById(`vimeo-player-${index}`) as HTMLIFrameElement;
      const player = new Player(iframe);

      player.on('play', () => setIsPlaying(true));
      player.on('pause', () => setIsPlaying(false));

      player.on('timeupdate', (data: { seconds: number }) => {
        const currentTime = data.seconds;
        const notationIndex = bloc.notationArray.findIndex(
          (notation: any, i: number) =>
            parseFloat(notation.time) <= currentTime &&
            (i === bloc.notationArray.length - 1 || currentTime < parseFloat(bloc.notationArray[i + 1].time))
        );

        if (notationIndex !== -1) {
          const currentNotation = bloc.notationArray[notationIndex];
          if (currentNotation.prosa2) {
            dispatch(setCurrentProsa(currentNotation.prosa2));
          }
          dispatch(setCurrentNotationIndex(notationIndex));
        }
      });
    }
  }, [bloc, index, dispatch]);

  useEffect(() => {
    if (isPlaying && startBeat && endBeat) {
      const startTime = parseFloat(startBeat);
      const endTime = parseFloat(endBeat);
  
      const iframe = document.getElementById(`vimeo-player-${index}`) as HTMLIFrameElement;
      const player = new Player(iframe);
  
      const checkLoop = async () => {
        try {
          const currentTime = await player.getCurrentTime();
      
          if (currentTime >= endTime) {
            // Move to the start time and pause
            await player.setCurrentTime(startTime);
            player.pause();
            setIsPlaying(false);
      
            if (playbackSpeed > 0.7) {
              // Start countdown logic (timing only, no UI)
              const previousBeats = Array.from({ length: 4 }, (_, i) => {
                const beat = (Math.round(startTime) - 4 + i + 1) % 8 || 8;
                return beat < 1 ? beat + 8 : beat;
              });
      
              setCountdownBeats([0, ...previousBeats.slice(1)]); // Use 0 for timing placeholder
              setCountdown(0); // Start with blank
              let beatIndex = 0;
      
              const countdownInterval = setInterval(() => {
                if (beatIndex === countdownBeats.length) {
                  clearInterval(countdownInterval);
      
                  // Play video from the start of the loop
                  player.setCurrentTime(startTime).catch((err) => console.error(err));
                  player.play();
                  setIsPlaying(true);
                } else {
                  beatIndex++; // Advance the timer without showing numbers
                }
              }, 1000);
            } else {
              // For speeds <= 0.7, just loop back without countdown
              player.setCurrentTime(startTime).catch((err) => console.error(err));
              player.play();
            }
          }
        } catch (error) {
          console.error('Error handling video loop:', error);
        }
      };
  
      const interval = setInterval(checkLoop, 200);
      return () => clearInterval(interval);
    }
  }, [isPlaying, startBeat, endBeat, playbackSpeed, index]);

  if (!bloc) {
    return <div>Loading...</div>;
  }

return (
  <CardContent>
    {bloc.description && (
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bloc.description) }} />
    )}

    <div className={styles.contentContainer}>
      <div className={styles.videoContainerWrapper}>
        <NotationList
          bloc={bloc}
          notationRefs={notationRefs}
          index={index}
          startBeat={startBeat}
          endBeat={endBeat}
          onStartBeatSelect={setStartBeat}
          onEndBeatSelect={setEndBeat}
        />
      </div>
    </div>

    <div className={styles.contentContainer}>
      <div className={styles.videoContainerWrapper}>
        {bloc.videoUrl && <VideoPlayer videoUrl={bloc.videoUrl} index={index} />}
        {showCountdown && (
          <div
            style={{
              position: 'absolute',
              top: '10%',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: '#ffffff',
              padding: '20px',
              borderRadius: '8px',
              fontSize: '48px',
              fontWeight: 'bold',
              textAlign: 'center',
              zIndex: 1000,
            }}
          >
            {countdown === -1 ? '' : countdown}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: '1.5rem',
          }}
        >
          <SpeedControl />
          <IconButton
            onClick={toggleProsa}
            style={{
              backgroundColor: '#004d40',
              color: '#ffffff',
              margin: '10px',
              borderRadius: '50%',
              padding: '10px',
            }}
            aria-label="Info"
          >
            <InfoIcon />
          </IconButton>
          <Prosa
            currentProsa={DOMPurify.sanitize(currentProsa)}
            rawFeedback={DOMPurify.sanitize(rawFeedback)}
            isOpen={isProsaOpen}
            onClose={toggleProsa}
          />
        </div>
      </div>
      {/* <FacebookShareButton blocId={blocId} /> Use the Facebook share button here */}
    </div>

    <Buttons blocId={blocId} />
  </CardContent>
);
};

export default BlocItem;
