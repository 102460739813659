import React, { useEffect, useState } from 'react';
import { ButtonGroup, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, setPlaybackSpeed } from '../Store'; // Adjust the import path as needed
import Player from '@vimeo/player'; // Assuming Vimeo player is being used
import styles from './SpeedControlStyles.module.css'; // Import the CSS module for styling

interface SpeedControlProps {
  player?: Player; // Optional Vimeo player instance
}

const SpeedControl: React.FC<SpeedControlProps> = ({ player }) => {
  const dispatch = useDispatch();
  const playbackSpeed = useSelector((state: RootState) => state.bloc.playbackSpeed);
  const availableSpeeds = [0.1, 0.25, 0.5, 0.75, 1];

  const [localPlayer, setLocalPlayer] = useState<Player | null>(null);

  useEffect(() => {
    // Initialize a local player instance if not passed as a prop
    if (!player && document.querySelector('iframe')) {
      const iframe = document.querySelector('iframe') as HTMLIFrameElement;
      setLocalPlayer(new Player(iframe));
    }
  }, [player]);

  const handleSpeedChange = async (speed: number) => {
    dispatch(setPlaybackSpeed(speed));

    // Use either the passed player or the local instance
    const targetPlayer = player || localPlayer;

    if (targetPlayer) {
      try {
        await targetPlayer.setPlaybackRate(speed);

        // Mute the volume if the speed is less than 0.70
        if (speed < 0.7) {
          targetPlayer.setVolume(0).catch((error) => {
            console.error('Error muting the volume:', error);
          });
        } else {
          targetPlayer.setVolume(1).catch((error) => {
            console.error('Error unmuting the volume:', error);
          });
        }
      } catch (error) {
        console.error('Error setting playback speed:', error);
      }
    }
  };

  return (
    <div className={styles.speedControlContainer}>
      <ButtonGroup variant="contained" aria-label="Playback Speed">
        {availableSpeeds.map((speed) => (
          <Button
            key={speed}
            onClick={() => handleSpeedChange(speed)}
            className={`${styles.speedButton} ${
              playbackSpeed === speed ? styles.active : styles.inactive
            }`}
          >
            {speed}x
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default SpeedControl;
