


import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Button, CircularProgress, Typography } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import axios from 'axios';

// Use your live Stripe public key
const stripePromise = loadStripe('pk_live_Vf5cEc0MGcfQGeHkVkk6Yh2T');

const SubscribePage: React.FC = () => {
  const [loadingMonthly, setLoadingMonthly] = useState(false);
  const [loadingPremium, setLoadingPremium] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(null);
  const [subscriptionType, setSubscriptionType] = useState<string | null>(null);

  const apiBaseUrl =
    window.location.hostname === 'localhost'
      ? 'https://stripe-firebase-server-807107572139.us-east1.run.app'
      : 'https://stripe-firebase-server-807107572139.us-east1.run.app';

  const db = getFirestore();

  useEffect(() => {
    const fetchSubscriptionInfo = async () => {
      try {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;
        if (!userId) return; // Not logged in, no subscription info needed
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setSubscriptionStatus(userData.subscriptionStatus || 'inactive');
          setSubscriptionType(userData.subscriptionType || null);
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching subscription info:', error);
      }
    };

    fetchSubscriptionInfo();
  }, [db]);

  const handleSubscribe = async (
    priceId: string,
    setLoadingState: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setLoadingState(true);
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();
      const userId = auth.currentUser?.uid;
      const email = auth.currentUser?.email;

      if (!token || !userId || !email) throw new Error('Authentication error');

      const response = await axios.post(
        `${apiBaseUrl}/api/checkout`,
        { priceId, userId, email },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const stripe = await stripePromise;
      await stripe?.redirectToCheckout({ sessionId: response.data.sessionId });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingState(false);
    }
  };

  const isActive = (type: string) => subscriptionStatus === 'active' && subscriptionType === type;

  return (
    <div style={{ fontFamily: "'Poppins', sans-serif", color: '#333', overflowX: 'hidden' }}>
      {/* Hero Section */}
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
          padding: '120px 20px',
          background: 'url("https://firebasestorage.googleapis.com/v0/b/tangovideo-8ed7a/o/438680873_3599631373684885_5612210569039544007_n.jpg?alt=media&token=56ef694e-8990-46ac-83df-c5158e93784e") no-repeat center center / cover',
          color: 'white',
          minHeight: '70vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.8))',
            zIndex: 1,
          }}
        />
        <div style={{ position: 'relative', zIndex: 2, maxWidth: '600px', margin: '0 auto' }}>
          <Typography variant="h2" style={{ fontWeight: 700, marginBottom: '20px' }}>
            Master Tango from Anywhere
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 400, marginBottom: '40px', lineHeight: '1.4' }}>
            Join live Zoom classes, access premium recorded lessons, and refine your technique at home.
          </Typography>
          <Button
            style={{
              backgroundColor: '#00796b',
              color: 'white',
              padding: '12px 24px',
              fontSize: '16px',
              fontWeight: 'bold',
              borderRadius: '30px',
              textTransform: 'none',
            }}
            onClick={() => {
              const pricingSection = document.getElementById('pricing');
              if (pricingSection) pricingSection.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            View Plans
          </Button>
        </div>
      </div>

                     {/* Value Proposition & Benefits */}
<div style={{ padding: '60px 20px', maxWidth: '1200px', margin: '0 auto', textAlign: 'center' }}>
        
        <Typography variant="h4" style={{ fontWeight: '700', marginBottom: '40px' }}>Why Tango Breakdown?</Typography>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '40px' }}>      <div style={{ maxWidth: '250px' }}>
            <img 
             src="https://firebasestorage.googleapis.com/v0/b/tangovideo-8ed7a/o/smgTvepind4.jpg?alt=media&token=f3bdf21f-e816-41a0-ba90-e85c7ad06e5b" 
             alt="Live Interactive Classes" 
             style={{ height: '200px', width: '200px', borderRadius: '50%', marginBottom: '20px' }} 
           />
           <Typography variant="h6" style={{ fontWeight: '600' }}>Live Interactive Classes</Typography>
           <Typography variant="body1" style={{ color: '#555', marginTop: '10px' }}>
             Get real-time feedback, ask questions, and learn from expert instructors in live Zoom sessions.
           </Typography>
         </div>
         <div style={{ maxWidth: '250px' }}>
           <img 
             src="https://firebasestorage.googleapis.com/v0/b/tangovideo-8ed7a/o/Screenshot%202024-12-16%20at%206.03.01%E2%80%AFPM.png?alt=media&token=b69a371b-7886-446c-b82e-50afea4118d9" 
             alt="Premium Lessons" 
             style={{ height: '200px', width: '200px', borderRadius: '50%', marginBottom: '20px' }} 
           />
           <Typography variant="h6" style={{ fontWeight: '600' }}>Premium Recorded Lessons</Typography>
           <Typography variant="body1" style={{ color: '#555', marginTop: '10px' }}>
             Access a comprehensive library of step-by-step breakdowns anytime, anywhere.
           </Typography>
         </div>
    
         <div style={{ maxWidth: '250px' }}>
         <div style={{ height: '200px', width: '200px', marginBottom: '20px' }}>  
         <img 
    src="https://firebasestorage.googleapis.com/v0/b/tangovideo-8ed7a/o/364039037_640158738255452_6912199166659801098_n.jpg?alt=media&token=966cbd9a-e96d-423b-b50d-ea2052e9dbea" 
    alt="Live Interactive Classes" 
    style={{ height: '100%', width: '100%', borderRadius: '50%' }} 
    />
    </div>          
         
    
           <Typography variant="h6" style={{ fontWeight: '600' }}>Expert Instructors</Typography>
           <Typography variant="body1" style={{ color: '#555', marginTop: '10px' }}>
             Learn from world-class tango professionals with years of teaching and performance experience.
           </Typography>
         </div>
    
         <div style={{ maxWidth: '250px' }}>
         <div style={{ height: '200px', width: '200px', marginBottom: '20px' }}>  
         <img 
    src="https://firebasestorage.googleapis.com/v0/b/tangovideo-8ed7a/o/508348245.jpg?alt=media&token=a0bbef8a-3906-4a66-ab83-f144a11b5ff2" 
    alt="Live Interactive Classes" 
    style={{ height: '100%', width: '100%', borderRadius: '50%' }} 
    />
    </div> 
           <Typography variant="h6" style={{ fontWeight: '600' }}>Flexible Learning</Typography>
           <Typography variant="body1" style={{ color: '#555', marginTop: '10px' }}>
             Train on your schedule, on any device, from the comfort of your own home.
           </Typography>
         </div>
       </div>
     </div>


      {/* Subscription Options */}
      <div id="pricing" style={{ background: '#f9f9f9', padding: '60px 20px' }}>
        <Typography variant="h4" style={{ fontWeight: '700', marginBottom: '40px', textAlign: 'center' }}>
          Choose Your Plan
        </Typography>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '40px' }}>
          {/* Monthly Plan */}
          <div
  style={{
    width: '300px',
    padding: '30px',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
    textAlign: 'center',
    border: '2px solid #00796b', // Updated to teal green
    opacity: isActive('TANGOVLOG_MONTHLY') ? 0.6 : 1,
  }}
>
  <Typography
    variant="h5"
    style={{
      marginTop: '20px',
      marginBottom: '16px',
      color: '#00796b', // Updated to teal green
      fontWeight: 700,
    }}
  >
    Monthly Access
  </Typography>
  <Typography style={{ marginBottom: '24px', fontSize: '18px', fontWeight: 400 }}>
    $10/month
  </Typography>
  <ul style={{ listStyle: 'none', padding: 0, margin: '0 0 30px', textAlign: 'left' }}>
    <li>✔ Access all post entries</li>
    <li>✔ Unlimited browsing</li>
  </ul>
  <Button
    style={{
      backgroundColor: isActive('TANGOVLOG_MONTHLY') ? '#ccc' : '#00796b', // Updated to teal green
      color: isActive('TANGOVLOG_MONTHLY') ? '#666' : 'white',
      padding: '10px 20px',
      borderRadius: '20px',
      textTransform: 'none',
    }}
    disabled={isActive('TANGOVLOG_MONTHLY')}
    onClick={() => handleSubscribe('TANGOVLOG_MONTHLY', setLoadingMonthly)}
  >
    {loadingMonthly ? (
      <CircularProgress size={24} />
    ) : isActive('TANGOVLOG_MONTHLY') ? (
      'Already Subscribed'
    ) : (
      'Choose Monthly'
    )}
  </Button>
</div>

          {/* Premium Plan */}
          <div
            style={{
              width: '300px',
              padding: '30px',
              background: '#fff',
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
              textAlign: 'center',
              border: '2px solid #e65100',
              opacity: isActive('Premium') ? 0.6 : 1,
            }}
          >
            <Typography variant="h5" style={{ marginTop: '20px', marginBottom: '16px', color: '#e65100', fontWeight: 700 }}>
              Premium Plan
            </Typography>
            <Typography style={{ marginBottom: '24px', fontSize: '18px', fontWeight: 400 }}>
              $90/month
            </Typography>
            <ul style={{ listStyle: 'none', padding: 0, margin: '0 0 30px', textAlign: 'left' }}>
              <li>✔ All Basic features</li>
              <li>✔ Live Zoom classes</li>
              <li>✔ Recordings of past classes</li>
            </ul>
            <Button
              style={{
                backgroundColor: isActive('Premium') ? '#ccc' : '#e65100',
                color: isActive('Premium') ? '#666' : 'white',
                padding: '10px 20px',
                borderRadius: '20px',
                textTransform: 'none',
              }}
              disabled={isActive('Premium')}
              onClick={() => handleSubscribe('price_1QTWDHIRn82SLOepJBThQ9rX', setLoadingPremium)}
            >
              {loadingPremium ? (
                <CircularProgress size={24} />
              ) : isActive('Premium') ? (
                'Already Subscribed'
              ) : (
                'Choose Premium'
              )}
            </Button>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div style={{ background: '#333', color: '#fff', textAlign: 'center', padding: '20px' }}>
        <Typography variant="body2" style={{ fontSize: '14px' }}>
          © {new Date().getFullYear()} Tango Breakdown. All rights reserved.
        </Typography>
      </div>
    </div>
  );
};

export default SubscribePage;