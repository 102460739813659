import React, { useState, useRef, useEffect } from 'react';
import DOMPurify from 'dompurify';

interface ProsaProps {
  currentProsa: string;
  rawFeedback: string;
  isOpen: boolean;
  onClose: () => void;
}

const Prosa: React.FC<ProsaProps> = ({ currentProsa, rawFeedback, isOpen, onClose }) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [dragging, setDragging] = useState(false);
  const [resizing, setResizing] = useState(false);
  const [position, setPosition] = useState({ x: 50, y: 50 });
  const [size, setSize] = useState({ width: 500, height: 400 });
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [resizeStart, setResizeStart] = useState({ width: 0, height: 0, x: 0, y: 0 });

  // const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
  //   const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
  //   const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

  //   setDragging(true);
  //   setDragStart({
  //     x: clientX - position.x,
  //     y: clientY - position.y,
  //   });
  // };

  const handleResizeStart = (e: React.MouseEvent | React.TouchEvent) => {
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

    setResizing(true);
    setResizeStart({
      width: size.width,
      height: size.height,
      x: clientX,
      y: clientY,
    });
    e.stopPropagation(); // Prevent drag event from triggering
  };

  const handleMove = (e: MouseEvent | TouchEvent) => {
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

    if (dragging) {
      setPosition({
        x: clientX - dragStart.x,
        y: clientY - dragStart.y,
      });
    }
    if (resizing) {
      setSize({
        width: Math.max(300, resizeStart.width + (clientX - resizeStart.x)),
        height: Math.max(200, resizeStart.height + (clientY - resizeStart.y)),
      });
    }
  };

  const handleEnd = () => {
    setDragging(false);
    setResizing(false);
  };

  useEffect(() => {
    if (dragging || resizing) {
      window.addEventListener('mousemove', handleMove);
      window.addEventListener('mouseup', handleEnd);
      window.addEventListener('touchmove', handleMove);
      window.addEventListener('touchend', handleEnd);
    }

    return () => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseup', handleEnd);
      window.removeEventListener('touchmove', handleMove);
      window.removeEventListener('touchend', handleEnd);
    };
  }, [dragging, resizing]);

  if (!isOpen) return null;

  return (
    <div
      ref={modalRef}
      style={{
        position: 'absolute',
        top: `${position.y}px`,
        left: `${position.x}px`,
        width: `${size.width}px`,
        height: `${size.height}px`,
        backgroundColor: '#e0f2f1',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        fontFamily: "'Helvetica Neue', sans-serif",
        color: '#004d40',
        overflowY: 'auto',
        zIndex: 1000,
        touchAction: 'none', // Disable default touch behavior
        cursor: dragging ? 'grabbing' : resizing ? 'nwse-resize' : 'grab',
      }}
      // onMouseDown={handleDragStart}
      // onTouchStart={handleDragStart}
    >
      {/* Close Button */}
      <button
        onClick={onClose}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
          fontSize: '18px',
          color: '#004d40',
        }}
      >
        ✖
      </button>

      {/* Prosa Content */}
      <div
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(currentProsa) }}
        style={{
          fontSize: '18px',
          lineHeight: '1.8',
          marginBottom: '20px',
          padding: '20px',
        }}
      />

      {/* Feedback Content */}
      <div
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rawFeedback) }}
        style={{
          fontSize: '18px',
          lineHeight: '1.8',
          marginTop: '20px',
          padding: '20px',
        }}
      />

      {/* Resize Handle */}
      <div
        onMouseDown={handleResizeStart}
        onTouchStart={handleResizeStart}
        style={{
          position: 'absolute',
          bottom: '0',
          right: '0',
          width: '20px',
          height: '20px',
          backgroundColor: '#004d40',
          cursor: 'nwse-resize',
          borderRadius: '0 0 8px 0',
        }}
      ></div>
    </div>
  );
};

export default Prosa;
