import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography } from '@mui/material';
import DateTimeDisplay from './DateTimeDisplay';
import { Lesson } from '../models/Lesson';

interface LessonCardProps {
  lesson: Lesson;
  userSubscription?: string; // Optional subscription type for conditional logic
}

const LessonCard: React.FC<LessonCardProps> = ({ lesson, userSubscription }) => {
  const navigate = useNavigate();

  // Click handler based on subscription and lesson properties
  const handleClick = () => {
    if (userSubscription === 'Premium') {
      if (lesson.videoUrl) {
        // Navigate to VimeoPlayer if videoUrl exists
        navigate(`/video/${lesson.id}`, { state: { lesson } });
      } else if (lesson.zoomLink) {
        // Open Zoom link if videoUrl is empty
        window.open(lesson.zoomLink, '_blank');
      } else {
        console.warn('No video URL or Zoom link provided for this lesson.');
      }
    } else {
      navigate('/subscribe');
    }
  };

  return (
    <Card
      style={{
        width: '350px', // Adjusted for a cleaner, proportional layout
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        margin: '20px',
      }}
    >
      {/* Landscape Image Section */}
      <div style={{ width: '100%', height: 'auto' }}>
        <img
          src={lesson.thumbnailUrl || 'default-image-url.png'}
          alt={lesson.topic}
          style={{
            width: '100%',
            height: 'auto', // Maintain aspect ratio
            display: 'block',
          }}
        />
      </div>

      {/* Card Content Section */}
      <CardContent
        style={{
          padding: '16px',
          backgroundColor: '#ffffff',
          textAlign: 'center',
        }}
      >
        <Typography
          style={{
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 700,
            fontSize: '18px',
            color: '#333',
            marginBottom: '8px',
          }}
        >
          {lesson.topic}
        </Typography>
        <DateTimeDisplay date={lesson.date} time={lesson.time} />
        <button
          onClick={handleClick}
          style={{
            textDecoration: 'none',
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 600, // Slightly bolder font for better readability
            fontSize: '18px', // Larger font size
            backgroundColor: lesson.videoUrl ? '#ff7043' : '#00796b', // Orange for video, teal for Zoom
            color: '#ffffff',
            padding: '12px 24px', // Larger button padding
            borderRadius: '25px',
            cursor: 'pointer',
            border: 'none',
            marginTop: '20px', // Add top margin
          }}
        >
          {lesson.videoUrl ? 'Open Video' : 'Join Zoom'}
        </button>
      </CardContent>
    </Card>
  );
};

export default LessonCard;
