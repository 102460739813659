import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Player from '@vimeo/player';

const VimeoPlayer: React.FC = () => {
  const location = useLocation();
  const lesson = location.state?.lesson; // Access the full lesson object
  const [currentTime, setCurrentTime] = useState(0);
  const listRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player | null>(null);
  const isMobile = window.innerWidth <= 768;

  // Handles scrolling to the active ZoomProsa item
  const handleScrollToActive = (index: number) => {
    const list = listRef.current;
    if (list) {
      const activeItem = list.querySelectorAll('.zoom-prosa-item')[index];
      if (activeItem) {
        activeItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  // Initialize the Vimeo player and set up timeupdate event
  useEffect(() => {
    if (lesson) {
      const iframe = document.querySelector('iframe');
      if (iframe) {
        const player = new Player(iframe);
        playerRef.current = player;

        player.on('timeupdate', ({ seconds }: { seconds: number }) => {
          setCurrentTime(seconds);
        });
      }
    }

    return () => {
      playerRef.current?.off('timeupdate');
    };
  }, [lesson]);

  // Highlight the active item based on video time
  const activeIndex = lesson
    ? lesson.zoomProsa.findIndex((item: any, index: number) => {
        const itemTime = parseFloat(item.time);
        const nextItemTime =
          lesson.zoomProsa[index + 1]
            ? parseFloat(lesson.zoomProsa[index + 1].time)
            : Infinity; // Use Infinity if there's no next item
        return currentTime >= itemTime && currentTime < nextItemTime;
      })
    : -1;

  useEffect(() => {
    if (activeIndex !== -1) {
      handleScrollToActive(activeIndex);
    }
  }, [activeIndex]);

  // Handles click event on a ZoomProsa item
  const handleItemClick = (time: string) => {
    const targetTime = parseFloat(time);
    if (playerRef.current) {
      playerRef.current.setCurrentTime(targetTime);
    }
  };

  if (!lesson) {
    return <div>Lesson data not provided.</div>;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        height: '100vh',
        backgroundColor: '#008080',
      }}
    >
      {/* Video Player Section */}
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: isMobile ? '0' : '16px', // Removed padding on mobile
          backgroundColor: '#008080',
          height: isMobile ? 'auto' : '100%', // Let the content dictate height on mobile
        }}
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
            maxWidth: isMobile ? '100%' : '640px', // Full width on mobile
            backgroundColor: 'rgb(0, 158, 158)', // Dark teal frame
            borderRadius: '8px',
            padding: isMobile ? '0' : '16px', // Removed inner padding on mobile
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          <iframe
            src={`https://player.vimeo.com/video/${lesson.videoUrl}`}
            title="Vimeo video player"
            style={{
              width: '100%',
              height: isMobile ? '240px' : '360px', // Adjusted height for mobile
              border: 'none',
              borderRadius: '8px',
            }}
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </div>
      </div>

      {/* Notes Section */}
      <div
        ref={listRef}
        style={{
          flex: 1,
          overflowX: isMobile ? 'auto' : 'hidden',
          overflowY: isMobile ? 'hidden' : 'auto',
          padding: isMobile ? '16px 0' : '16px',
          backgroundColor: '#008080', // Teal background
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {lesson.zoomProsa.map((item: any, index: number) => (
          <div
            key={index}
            className={`zoom-prosa-item ${
              index === activeIndex ? 'active' : ''
            }`}
            onClick={() => handleItemClick(item.time)}
            style={{
              padding: '10px',
              margin: isMobile ? '0 10px' : '10px 0',
              borderRadius: '6px',
              backgroundColor: index === activeIndex ? '#006666' : '#f9f9f9',
              color: index === activeIndex ? '#ffffff' : '#000000',
              boxShadow:
                index === activeIndex
                  ? '0 2px 8px rgba(0, 0, 0, 0.2)'
                  : '0 1px 3px rgba(0, 0, 0, 0.1)',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
              width: isMobile ? '90%' : '100%', // Adjusted card width
              maxWidth: '640px', // Match video width on desktop
              textAlign: 'center',
              minWidth: isMobile ? '90%' : '100%',
            }}
            dangerouslySetInnerHTML={{ __html: item.details }}
          />
        ))}
      </div>
    </div>
  );
};

export default VimeoPlayer;
