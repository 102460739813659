import React, { useEffect, useState } from 'react';
import { ThemeProvider, Grid, Button, Typography } from '@mui/material';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import theme from '../theme';
import { Bloc } from './models/Index';
import BlocCard from './BlocCard';
import { useAuth } from '../context/AuthContext';

const ITEMS_PER_PAGE = 9;

const BlocsListPage: React.FC = () => {
  const [blocs, setBlocs] = useState<Bloc[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { isSubscribed } = useAuth();

  const totalPages = Math.ceil(blocs.length / ITEMS_PER_PAGE);

  useEffect(() => {
    const fetchBlocs = async () => {
      try {
        const blocsQuery = query(collection(db, 'blocs'), orderBy('timestamp', 'desc'));
        const blocsSnapshot = await getDocs(blocsQuery);

        const blocsData = blocsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Bloc[];

        setBlocs(blocsData);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch blocs:', error);
      }
    };

    fetchBlocs();
  }, []);

  const paginatedBlocs = blocs.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleItemClick = (bloc: Bloc) => {
    if (!bloc.public && !isSubscribed) {
      // Redirect to subscription page if bloc is not public and user is not subscribed
      navigate('/subscribe');
    } else {
      // Allow access to the bloc
      navigate(`/bloc/${bloc.id}`);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ textAlign: 'center', padding: '40px 20px', backgroundColor: '#fafafa' }}>
        <Typography variant="h4" style={{ fontWeight: 'bold', color: '#00796b', marginBottom: '20px' }}>
          Explore Tango Lessons
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Discover a wide range of tango lessons to improve your skills.
        </Typography>
      </div>

      {loading ? (
        <Typography variant="h6" style={{ textAlign: 'center', padding: '20px' }}>
          Loading lessons...
        </Typography>
      ) : (
        <>
          {/* Bloc Cards */}
          <Grid container spacing={3} style={{ padding: '20px' }}>
            {paginatedBlocs.map((bloc, index) => (
              <Grid item xs={12} sm={6} md={4} key={bloc.id}>
                <BlocCard bloc={bloc} index={index} onClick={() => handleItemClick(bloc)} />
              </Grid>
            ))}
          </Grid>

          {/* Pagination Controls */}
          <div
            style={{
              textAlign: 'center',
              padding: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Button
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
              style={{ marginRight: '8px' }}
            >
              First
            </Button>
            <Button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              style={{ marginRight: '8px' }}
            >
              Previous
            </Button>
            {/* Page Number Buttons */}
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <Button
                key={page}
                onClick={() => setCurrentPage(page)}
                variant={page === currentPage ? 'contained' : 'outlined'}
                style={{
                  margin: '0 4px',
                  minWidth: '40px',
                  padding: '6px 12px',
                  backgroundColor: page === currentPage ? '#00796b' : 'transparent',
                  color: page === currentPage ? 'white' : '#00796b',
                  borderColor: '#00796b',
                }}
              >
                {page}
              </Button>
            ))}
            <Button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              style={{ marginLeft: '8px' }}
            >
              Next
            </Button>
            <Button
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
              style={{ marginLeft: '8px' }}
            >
              Last
            </Button>
          </div>
        </>
      )}
    </ThemeProvider>
  );
};

export default BlocsListPage;
