import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Bloc } from './models/Index';

interface BlocCardProps {
  bloc: Bloc;
  onClick: () => void;
  index: number;
}

const BlocCard: React.FC<BlocCardProps> = ({ bloc, onClick }) => {
  return (
    <Card
      onClick={onClick}
      style={{
        cursor: 'pointer',
        borderRadius: '12px',
        overflow: 'hidden',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s',
        width: '100%',
        maxWidth: '400px', // Optional: Limit card width
      }}
    >
      {/* Image Section */}
      <div
        style={{
          position: 'relative',
          width: '100%',
          paddingBottom: '52.33%', // This ensures the 1200x628 (1.91:1) aspect ratio
          overflow: 'hidden',
        }}
      >
        <img
          src={bloc.thumbnailUrl || 'default-image-url.png'}
          alt={bloc.title || 'Card Image'}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover', // Fill the area completely while preserving aspect ratio
            objectPosition: 'center',
          }}
        />
      </div>

      {/* Card Details Section */}
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '16px',
          textAlign: 'center',
        }}
      >
        <Typography
          style={{
            fontWeight: 700,
            fontSize: '18px',
            marginBottom: '8px',
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          {bloc.title || 'Untitled'}
        </Typography>
        {/* <Typography
          style={{
            fontSize: '14px',
            color: '#666',
            marginBottom: '12px',
            fontFamily: "'Roboto', sans-serif",
          }}
        >
          {bloc.description || 'No description available.'}
        </Typography> */}
        <button
          style={{
            backgroundColor: '#00796b',
            color: 'white',
            padding: '10px 20px',
            borderRadius: '20px',
            fontSize: '14px',
            fontWeight: 500,
            border: 'none',
            cursor: 'pointer',
            textTransform: 'none',
          }}
        >
          {bloc.videoUrl ? 'Watch Video' : 'View Details'}
        </button>
      </CardContent>
    </Card>
  );
};

export default BlocCard;
