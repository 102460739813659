import React, { ReactElement, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';

import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import LessonDetailsPage from './components/LessonDetailsPage';
import NavBar from './components/NavBar';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { AuthProvider, useAuth } from './context/AuthContext';
import { SelectedUserProvider } from './context/SelectedUserContext';

import StudentVideoUpload from './components/StudentVideoUpload';
import FeedbackPageLive from './components/FeedbackPageLive';
import QuizPage from './components/QuizPage';
import BlocsListPage from './components/BlocsListPage';
import NotationDetailsPage from './components/NotationDetailsPage';
import SubscribePage from './components/SubscribePage';
import SuccessPage from './components/SuccessPage';
import ProfilePage from './components/ProfilePage';
import Game from './components/Game';
import ARSwipeWalkthrough from './components/ARSwipeWalkthrough'; // Import ARSwipeWalkthrough
import NotationMatches from './components/NotationMatches'; // Import the new NotationMatches component
import ZoomClassesDashboard from './components/Zoom/ZoomClassesDashboard'; // Import the ZoomClassesDashboard component
import VimeoPlayer from './components/Zoom/VimeoPlayer';

// Import Provider and store
import { Provider } from 'react-redux';
import { store } from './Store';

// Google Analytics setup
import ReactGA from 'react-ga4';
import RouteLogger from './components/RouteLogger'; // Import the RouteLogger component

const trackingId = 'G-02177KRZT9';
ReactGA.initialize(trackingId);

// Function to track page views
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);
};

const PrivateRoute = ({ children }: { children: ReactElement }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/signin" replace />;
};

const PublicRoute = ({ children }: { children: ReactElement }) => {
  const { user } = useAuth();
  return !user ? children : <Navigate to="/" replace />;
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SelectedUserProvider>
          <Provider store={store}>
            <BrowserRouter>
              <NavBar />
              <RouteLogger /> {/* Add RouteLogger here */}
              <PageTracker />
              <div className="App">
                <Routes>
                  <Route path="/" element={<BlocsListPage />} />
                  <Route
                    path="/signin"
                    element={
                      <PublicRoute>
                        <SignIn />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/signup"
                    element={
                      <PublicRoute>
                        <SignUp />
                      </PublicRoute>
                    }
                  />
                  <Route path="/bloc/:blocId" element={<LessonDetailsPage />} />
                  <Route path="/quiz/:courseId/:lessonId/:blocId" element={<QuizPage />} />
                  <Route
                    path="/notation-details"
                    element={<NotationDetailsPage blocId={''} notationIndex={0} />}
                  />
                  <Route
                    path="/upload/:courseId/:lessonId/:blocId"
                    element={
                      <PrivateRoute>
                        <StudentVideoUpload blocId={''} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/feedback-live/:blocId/:combinedBeat"
                    element={
                      <PrivateRoute>
                        <FeedbackPageLive
                          blocId={''}
                          combinedBeat={''}
                          currentVideoTime={0}
                          onSaveTime={(time: number) => {}}
                        />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/subscribe"
                    element={
                      <PrivateRoute>
                        <SubscribePage />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/success" element={<SuccessPage />} />
                  <Route
                    path="/profile"
                    element={
                      <PrivateRoute>
                        <ProfilePage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/game/:blocId"
                    element={
                      <PrivateRoute>
                        <Game />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/ar-walkthrough/:blocId"
                    element={
                      <PrivateRoute>
                        <ARSwipeWalkthrough />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/notation-matches"
                    element={
                      <PrivateRoute>
                        <NotationMatches />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/zoomclasses"
                    element={
                      <PrivateRoute>
                        <ZoomClassesDashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/video/:videoId" element={<VimeoPlayer />} />
                </Routes>
              </div>
            </BrowserRouter>
          </Provider>
        </SelectedUserProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

const PageTracker: React.FC = () => {
  usePageTracking();
  return null;
};

export default App;