import { Timestamp } from 'firebase/firestore';

interface DateTimeDisplayProps {
  date: Timestamp; // Use the imported Timestamp type
  time: string;
}

const DateTimeDisplay: React.FC<DateTimeDisplayProps> = ({ date, time }) => {
  const formattedDate = date.toDate().toLocaleDateString(undefined, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <div style={{ textAlign: 'left' }}>
      <div
        style={{
          fontFamily: "'Roboto', sans-serif",
          fontSize: '18px',
          color: '#000',
        }}
      >
        {formattedDate}
      </div>
      <div
        style={{
          fontFamily: "'Poppins', sans-serif",
          fontSize: '24px',
          fontWeight: 700,
          color: '#000',
        }}
      >
        {time}
      </div>
    </div>
  );
};

export default DateTimeDisplay;
