import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase-config"; // Adjust the import to your Firebase config

const EXCLUDED_USER_IDS = ["8dy0t60qvKZlTGfe8LkNYpFXkRq2", "rmv1O1QVm7fnezwFogEv1SFRVO32"];

const RouteLogger: React.FC = () => {
  const location = useLocation();
  const auth = getAuth();

  useEffect(() => {
    const logRoute = async () => {
      try {
        const user = auth.currentUser;

        // Only log if a user is logged in and their ID is not excluded
        if (user && !EXCLUDED_USER_IDS.includes(user.uid)) {
          const logEntry = {
            userId: user.uid,
            email: user.email || "Unknown Email",
            route: location.pathname,
            timestamp: new Date().toISOString(),
          };

          await addDoc(collection(db, "logs"), logEntry);
          console.log("Log saved:", logEntry);
        } else {
          console.log("Logging skipped for excluded user or no user logged in.");
        }
      } catch (error) {
        console.error("Error logging route:", error);
      }
    };

    logRoute();
  }, [location, auth]);

  return null; // This component does not render anything visible
};

export default RouteLogger;
